.image {
    display: block;
    height: 100px;
    width: 100px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}