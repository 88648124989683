.questionsContainer {
    text-align: center;
    margin: 2% auto;
    width: 100%;
    max-width: 1000px;
    padding: 10px;
}

.btn {
    width: 40%;
}

@media screen and (max-width: 600px) {
    .questionsContainer {
        margin: 5% auto;
    }
}