.mainContainer {
    margin: auto;
    margin-top: 5%;
    width: 100%;
    background-color: var(--cardBgWhite) !important;
}

.secondaryContainer {
    margin: auto;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 2%;
    margin-bottom: 5%;
    vertical-align: middle !important;
}

.wrapper {
    margin: auto;
    /* vertical-align: middle;
    text-align: center; */
    width: 100%;
}

.reader {
   margin: auto;
   width: 30%;
}

.alertBox {
    margin: auto;
    width: 100%;
    border: solid 1px lightgray;
    border-radius: 4px;
}

.input {
    display: none;
}

.label {
    background-color: #1976d2;
    color: #fff;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.resultContainer {
    margin: auto;
    margin-bottom: 5%;
    /* margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    border: solid 1px black;
    border-radius: 4px; */
}

.resultItem {
    margin-top: 2%;
    border: solid 1px black;
    border-radius: 4px;
    cursor: pointer;
}

.resultItemErr {
    margin-top: 2%;
    border: solid 1px black;
    border-radius: 4px;
}