.dropdownContainer {
    margin: auto;
    margin-top: 2%;
    width: 100%;
    max-width: 500px;
    padding: 10px;
    background-color: var(--cardBgWhite) !important;
}

.dropdownCardInfo {
    text-align: center;
}