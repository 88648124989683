.container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .container {
        padding: 0 !important;
    }
}