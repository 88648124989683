.mainContainer {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
}

.sectionContainer {
    margin: auto;
    margin-top: 2%;
    width: 100%;
}

.header {
    margin-top: 2%;
}