.container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    background-color: var(--cardBgWhite) !important;
    align-items: center;
}
.btn {
    width: 40%;
}

@media screen and (max-width: 700px) {
    .container {
        padding: 0;
    }
}