.centeredContainer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 700px) {
    .centeredContainer {
        padding: 0 8px !important;
    }
}