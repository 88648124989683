.container {
    padding: 0 !important;
}
.buttonCard {
    background-color: var(--cardBgWhite) !important;
    padding: 2%;
}
.buttonRow {
    justify-content: center;
}
.btn {
    width: 40%;
}