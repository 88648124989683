.centeredContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90% !important;
    flex-direction: column;
    padding: 0 0;
}

.sliderContainer {
    height: 25px;
    width: 100%;
    position: relative;
}

.sliderTexts {
    position: relative;
    padding: 0;
    width: 100%;
    height: 1em;
}

.sliderTexts p {
    position: absolute;
    transform: translateX(-50%);
    white-space: nowrap;
}

.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-left: -12px;
    display: inline-block;
    position: absolute;
}

.exampleDot {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
    margin: auto 4px;
}

.line {
    top: 11px;
    width: 100%;
    border-top: 3px solid;
    border-color: green;
    opacity: 0.40;
    border-radius: 3px;
    height: 0;
    position: absolute;
}

.verticalLine {
    border-left: 2px solid;
    border-color: green;
    height: 9px;
    top: 8px;
    position: absolute;
}

@media screen and (max-width: 700px) {
    .dot {
        height: 13px;
        width: 13px;
        margin-left: -6px;
    }

    .line {
        top: 5px;
    }

    .verticalLine {
        height: 7px;
        top: 3px;
    }
    .sliderTexts p {
        font-size: 0.75rem;
        font-weight: lighter;
    }
}