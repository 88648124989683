.sampleBox {
    height: 50px;
    width: 50px;
}

.icon {
    height: 50px;
    width: 50px;
}

.container {
    display: flex;
    align-items: center;
    justify-content: left;
}