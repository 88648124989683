.mainContainer {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    background-color:#FAFAFA !important;
}

.secondaryContainer {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    height: 528px;
}

.deleteBtn {
    margin-bottom: 5%;
}
