.headingContainer {
    text-align: center;
    /* border: 1px solid black; */
    padding: 10px;
    margin: 16px auto;
    max-width: 600px;
    background-color: var(--cardBgWhite) !important;
}
  
.headingText {
    margin: 0;
    padding: 0;
}