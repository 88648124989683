:root {
  --cardBgWhite: #FAFAFA;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  width: 100%;
  background-image: url("bg2.svg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

a {
  color: inherit;
  text-decoration: none;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loginCard {
  width: 50%;
  min-height: 20vh;
}

@media (max-width: 600px) {
  .loginCard {
      width: 90%;
  }
}