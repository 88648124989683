.dragAndDropZone {
    border: 2px dashed gray;
    background-color: #fafafa;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.container {
    width: 100%;
    padding: 1em;
}

.uploadIcon {
    height: 50px;
    width: 50px;
}

.dropZoneContent {
    z-index: 100;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accept {
    border: 2px solid gray;
    background: repeating-linear-gradient(
            45deg,
            #fafafa,
            #fafafa 10px,
            #dadada 10px,
            #dadada 20px
    );
}

.reject {
    border: 2px solid #b70f0f;
    background: repeating-linear-gradient(
            45deg,
            #fafafa,
            #fafafa 10px,
            #b70f0f 10px,
            #b70f0f 20px
    );
}