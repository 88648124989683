.customContainer {
    min-width: 100%;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    min-height: 20vh;
}

@media (max-width: 600px) {
    .card {
        width: 90%;
    }
}