.questionBlock {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 0 0.75em;
    background-color: var(--cardBgWhite) !important;
  }
  
.sliderBlock {
  width: 80%;
  margin: auto;
  margin-bottom: 2%;
}
