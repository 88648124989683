.customContainer {
    min-width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centerContainer {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}