.mainContainer {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 10px;
}

.stack {
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    align-items: center;
}

.icon {
    width: 2rem !important;
    height: 2rem !important;
}

.mainHeader {
    font-size: 2rem;
    padding-left: 5px;
}