.flag {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
    height: 28px;
    width: 28px;
}

.centered {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}