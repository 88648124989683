.mainContainer {
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    background-color: var(--cardBgWhite) !important;
}

.secondaryContainer {
    margin-bottom: 5%;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
}

.resultContainer {
    margin: auto;
    margin-bottom: 5%;
}

.resultItem {
    margin-top: 2%;
    border: solid 1px black;
    border-radius: 4px;
    cursor: pointer;
}

.resultItemErr {
    margin-top: 2%;
    border: solid 1px black;
    border-radius: 4px;
}